import { ENUM_MODES, ENUM_PAGE_MODES, ENUM_TIER } from '@/enums/Enums'
import { DURATION_VALUES, ENUM_ROLES, MODE_CONSTANTS, STATUS_CONSTANTS } from '@/constants/constants'
import { DropdownOptionT, ModifiedExtendedReservationT } from '@/types/globalTypes'
import { TranslationModule } from '@/i18n'
import { subHours, differenceInMinutes } from 'date-fns'

interface InitialStateProps {
    mode: ENUM_MODES
    reservation: ModifiedExtendedReservationT
    initialTimes?: {
        startTime: string
        endTime: string
    }
    date: string
    venueId: string
    pageMode: ENUM_PAGE_MODES
    userRoles: string[]
    preselectedTableId?: string
    preferred_table_type?: ENUM_TIER
}

type TimePreferencesTextType = TranslationModule['TIME_PREFERENCES_TEXT']


const getCreateModeValues = (props: InitialStateProps) => {
    const isCustomerPage = props.pageMode === ENUM_PAGE_MODES.CUSTOMER

    const isTemporary = isCustomerPage ? true : props.reservation.is_temporary
    const status = isCustomerPage ? STATUS_CONSTANTS.PENDING : STATUS_CONSTANTS.CONFIRMED
    return {
        id: '',
        name_of_the_person: '',
        date_of_reservation: props.date,
        phone_number: '',
        email: '',  // Initialize as empty string
        people: 2,
        start_time: props.initialTimes?.startTime || '00:00',
        end_time: props.initialTimes?.endTime || '00:00',
        extra_information: '',
        status: status,
        is_walk_in: isCustomerPage ? false : props.reservation.is_walk_in,
        agreed_to_terms: false,
        is_active: false,
        preferred_table_type: props.preferred_table_type,
        is_temporary: isTemporary,
    }
}

const getEditModeValues = (reservation: ModifiedExtendedReservationT) => ({
    id: reservation.id,
    name_of_the_person: reservation.name_of_the_person,
    date_of_reservation: reservation.date_of_reservation,
    phone_number: reservation.phone_number,
    email: reservation.email || '',  // Handle potential null/undefined case
    people: reservation.people,
    start_time: reservation.start_time,
    end_time: reservation.end_time,
    extra_information: reservation.extra_information,
    status: reservation.status,
    is_walk_in: reservation.is_walk_in,
    agreed_to_terms: reservation.agreed_to_terms,
    is_active: reservation.is_active,
    is_temporary: reservation.is_temporary,
    preferred_table_type: reservation.preferred_table_type || ENUM_TIER.STANDARD,
})

export const getDefaultRole = (userRoles: string[]) => {
    const isManager = userRoles.includes(ENUM_ROLES.MANAGER)
    const isHost = userRoles.includes(ENUM_ROLES.HOST)

    if (isManager) return ENUM_ROLES.MANAGER
    if (isHost) return ENUM_ROLES.HOST
    return ENUM_ROLES.CUSTOMER
}

export const getInitialStateValues = (props: InitialStateProps) => {

    const isCreateMode = props.mode === MODE_CONSTANTS.CREATE
    const modeSpecificValues = isCreateMode
        ? getCreateModeValues(props)
        : getEditModeValues(props.reservation)

    return {
        ...modeSpecificValues,
        date_of_reservation: modeSpecificValues.date_of_reservation,
        venue_id: props.venueId,
        created_by_role: getDefaultRole(props.userRoles),
        table_id: props.preselectedTableId || '',
    }
}

export const generateTimeOptionsWithinRange = (
    start: Date,
    end: Date,
    stepMinutes: number
): DropdownOptionT[] => {
    const times = new Set<string>() // Use Set to automatically handle duplicates
    const options: DropdownOptionT[] = []
    let current = new Date(start)
    const endTime = new Date(end)

    /*  // Normalize dates to handle midnight transition
     if (endTime < start) {
         endTime.setDate(endTime.getDate() + 1)
     } */

    // Pre-calculate total minutes to prevent infinite loops
    const totalMinutes = Math.floor((endTime.getTime() - current.getTime()) / (60 * 1000))
    const iterations = Math.min(Math.ceil(totalMinutes / stepMinutes), 96) // 96 = 24hours * 4(15min intervals)

    for (let i = 0; i <= iterations; i++) {
        const hours = current.getHours()
        const minutes = current.getMinutes()
        const timeStr = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

        if (!times.has(timeStr)) {
            times.add(timeStr)
            options.push({
                value: timeStr,
                label: current.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true
                })
            })
        }

        current = new Date(current.getTime() + stepMinutes * 60000)
        if (current > endTime) break
    }

    return options
}

export const getTimeOptions = (
    queryStartDate: Date,
    venueStartingHours: string,
    venueEndingHours: string,
    initialTime?: string,
    currentTime?: Date  // Add new parameter for current time
): DropdownOptionT[] => {
    const [startHours, startMinutes] = venueStartingHours.split(':').map(Number)
    const [endHours, endMinutes] = venueEndingHours.split(':').map(Number)

    const startDate = new Date(queryStartDate)
    startDate.setHours(startHours, startMinutes)

    const endDate = new Date(queryStartDate)
    endDate.setHours(endHours, endMinutes)

    // If we're booking for today and have current time, use it as minimum time
    const isToday = currentTime &&
        currentTime.getFullYear() === queryStartDate.getFullYear() &&
        currentTime.getMonth() === queryStartDate.getMonth() &&
        currentTime.getDate() === queryStartDate.getDate()

    // If today, use the later of venue start time or current time (plus buffer)
    let effectiveStartTime = startDate
    if (isToday && currentTime) {
        // Create a new date object with current time
        const currentTimeAdjusted = new Date(currentTime)

        // Round up to the next 15-minute interval
        const minutes = currentTimeAdjusted.getMinutes()
        const roundedMinutes = Math.ceil(minutes / 15) * 15
        currentTimeAdjusted.setMinutes(roundedMinutes, 0) // Set seconds to 0

        // Add a small buffer (just one 15-min interval)
        const bufferTime = new Date(currentTimeAdjusted)
        bufferTime.setMinutes(bufferTime.getMinutes())

        // Use the later of venue opening or adjusted current time
        if (bufferTime > startDate) {
            effectiveStartTime = bufferTime
        } else {
            effectiveStartTime = startDate
        }
    }

    if (initialTime) {
        const [startHours, startMinutes] = venueStartingHours.split(':').map(Number)
        const venueStart = new Date(queryStartDate)
        venueStart.setHours(startHours, startMinutes)

        const [selectedHours, selectedMinutes] = initialTime.split(':').map(Number)
        const selectedStart = new Date(queryStartDate)
        selectedStart.setHours(selectedHours, selectedMinutes)

        if (selectedStart >= venueStart && selectedHours < 24) {
            const maxStartTime = new Date(queryStartDate)
            maxStartTime.setHours(0, 0)
            return generateTimeOptionsWithinRange(selectedStart, maxStartTime, 15)
        }
    }

    // Special case for venues that close exactly at midnight
    const closesAtMidnight = venueEndingHours === '00:00:00';

    // Determine if venue operates past midnight (excluding the 00:00 closing case)
    const isOvernight = !closesAtMidnight && endDate < startDate;

    if (closesAtMidnight) {
        // For venues that close exactly at midnight, set last booking to 23:00
        const lastBookingTime = new Date(queryStartDate);
        lastBookingTime.setHours(23, 0, 0, 0);
        return generateTimeOptionsWithinRange(effectiveStartTime, lastBookingTime, 15);
    } else if (isOvernight) {
        // For venues operating past midnight
        endDate.setDate(endDate.getDate() + 1);

        // For overnight venues, limit the last start time to midnight
        const midnightLimit = new Date(queryStartDate);
        midnightLimit.setHours(0, 0, 0, 0);
        midnightLimit.setDate(midnightLimit.getDate() + 1);

        // Use midnight as the cutoff for new reservations
        const lastAvailableStartTime = midnightLimit;
        return generateTimeOptionsWithinRange(effectiveStartTime, lastAvailableStartTime, 15);
    } else {
        // For regular venues (closing before midnight)
        // Keep the 1-hour buffer before closing
        const lastAvailableStartTime = subHours(endDate, 1);
        return generateTimeOptionsWithinRange(effectiveStartTime, lastAvailableStartTime, 15);
    }
}

export const getDurationOptions = (
    TIME_PREFERENCES_TEXT: TimePreferencesTextType,
    startTime?: string,
    endingHours?: string,
    queryDate?: Date
) => {
    const baseOptions = [
        { value: DURATION_VALUES.ONE_HOUR, label: TIME_PREFERENCES_TEXT.ONE_HOUR, hours: 1 },
        { value: DURATION_VALUES.TWO_HOURS, label: TIME_PREFERENCES_TEXT.TWO_HOURS, hours: 2 },
        { value: DURATION_VALUES.THREE_HOURS, label: TIME_PREFERENCES_TEXT.THREE_HOURS, hours: 3 },
        { value: DURATION_VALUES.FOUR_HOURS, label: TIME_PREFERENCES_TEXT.FOUR_HOURS, hours: 4 },
        { value: DURATION_VALUES.FIVE_HOURS, label: TIME_PREFERENCES_TEXT.FIVE_HOURS, hours: 5 },
        { value: DURATION_VALUES.UNTIL_CLOSE, label: TIME_PREFERENCES_TEXT.UNTIL_CLOSE, hours: 24 },
    ]

    if (!startTime || !endingHours || !queryDate) {
        return baseOptions.map(({ value, label }) => ({ value, label }))
    }

    // Calculate available time until closing
    const startDate = new Date(queryDate)
    const [startHours, startMinutes] = startTime.split(':').map(Number)
    startDate.setHours(startHours, startMinutes)

    const endDate = new Date(queryDate)
    const [endHours, endMinutes] = endingHours.split(':').map(Number)
    endDate.setHours(endHours, endMinutes)

    // Determine if venue operates overnight
    const isOvernight = endDate < startDate
    if (isOvernight) {
        endDate.setDate(endDate.getDate() + 1)

        // Get the midnight threshold
        const midnight = new Date(queryDate)
        midnight.setHours(0, 0, 0, 0)
        midnight.setDate(midnight.getDate() + 1)

        // If start time is after 23:00, only allow "until close" option
        const lateNightThreshold = new Date(queryDate)
        lateNightThreshold.setHours(23, 0, 0, 0)

        if (startDate >= lateNightThreshold) {
            return [{
                value: DURATION_VALUES.UNTIL_CLOSE,
                label: TIME_PREFERENCES_TEXT.UNTIL_CLOSE
            }]
        }
    }

    const availableMinutes = differenceInMinutes(endDate, startDate)
    const availableHours = availableMinutes / 60


    const filteredOptions = baseOptions.filter(option => {
        if (option.value === DURATION_VALUES.UNTIL_CLOSE) {
            return true
        }
        return option.hours < availableHours
    })

    return filteredOptions.map(({ value, label }) => ({ value, label }))
}

export const normalizeTimeFormat = (time: string): string => {
    // If time already has seconds, return as is
    if (time.split(':').length === 3) return time;
    // Add :00 seconds if not present
    return `${time}:00`;
};
