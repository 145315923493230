import React, { CSSProperties, ReactNode, useState } from 'react'

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

import Image, { ImageProps } from 'next/image'

interface CustomImageComponentProps {
    fill?: boolean
    className?: string
    containerClassname?: string
    loadingSpinnerClassName?: string
    loadingSpinnerSvgClassName?: string
    imageUrl: string
    alt?: string
    quality?: number
    beforeLoadingTransition?: string
    afterLoadingTransition?: string
    children?: ReactNode
    style?: CSSProperties
    width?: number
    height?: number
    isBase64?: boolean // New prop to indicate if the image is a base64 data URL
}

// Creating a union type for Image component props and custom props
type ImageComponentProps = CustomImageComponentProps & Partial<ImageProps>

const ImageComponent = ({
    fill,
    className,
    containerClassname,
    loadingSpinnerClassName,
    loadingSpinnerSvgClassName,
    imageUrl,
    alt,
    quality,
    beforeLoadingTransition,
    afterLoadingTransition,
    children,
    style,
    width,
    height,
    isBase64 = false, // Default to false
    ...imageProps // Rest of the image props
}: ImageComponentProps) => {
    const [loading, setLoading] = useState(true)

    const defaultClassName = 'object-contain w-full'
    const defaultContainerClassname =
        'overflow-hidden rounded-lg dark:bg-slate-50 dark:bg-opacity-5 bg-base-100 relative flex'
    const defaultBeforeLoadingTransition = 'scale-105 blur-2xl grayscale'
    const defaultAfterLoadingTransition = 'scale-100 blur-0 grayscale-0'
    const defaultLoadingSpinnerClassName =
        'absolute w-fit text-center top-[45%] left-0 right-0 m-auto z-[99999]'

    // Function to construct the wsrv.nl URL
    const getWsrvUrl = (imageUrl: string, width?: number, height?: number, quality?: number) => {
        // Skip wsrv.nl for base64 data URLs or URLs that start with "data:"
        if (isBase64 || imageUrl?.startsWith('data:')) {
            return imageUrl;
        }

        let url = `https://wsrv.nl/?url=${encodeURIComponent(imageUrl)}`
        if (width) {
            url += `&w=${width}`
        }
        if (height) {
            url += `&h=${height}`
        }
        if (quality) {
            url += `&q=${quality}`
        }
        return url
    }

    // Auto-detect if this is a data URL if isBase64 wasn't explicitly set
    const finalImageUrl = getWsrvUrl(imageUrl, width, height, quality)

    return (
        <div className={`${containerClassname || defaultContainerClassname}`}>
            <Image
                style={style}
                alt={alt || 'image'}
                quality={quality}
                src={finalImageUrl}
                fill={fill}
                width={width}
                height={height}
                className={`
                  ${className || defaultClassName}
                  ${
                      loading
                          ? beforeLoadingTransition || defaultBeforeLoadingTransition
                          : afterLoadingTransition || defaultAfterLoadingTransition
                  }`}
                onLoad={() => setLoading(false)}
                {...imageProps}
            />
            {loading ? (
                <LoadingSpinner
                    svgClassName={loadingSpinnerSvgClassName || ''}
                    className={`${loadingSpinnerClassName || defaultLoadingSpinnerClassName} `}
                />
            ) : null}
            {children}
        </div>
    )
}

export default ImageComponent
