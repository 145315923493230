import React from 'react'

import { FaceSmileIcon } from '@heroicons/react/24/outline'

import { useGetTranslations } from '@/i18n'

import ActionButtons from '@/components/shared/ActionButtons/ActionButtons'

import { BasicButtonProps } from '@/types/globalTypes'

interface ReservationConfirmationPopupComponentProps {
    requireManualCheck?: boolean
    onClose: () => void
    countdown: number
    isButtonDisabled: boolean
}

const ReservationConfirmationPopupComponent: React.FC<
    ReservationConfirmationPopupComponentProps
> = ({ requireManualCheck = true, onClose, countdown, isButtonDisabled }) => {
    const { BOOKING_PAGE_TEXT, ACTION_BUTTONS_LABELS } = useGetTranslations()

    const buttonLabel = isButtonDisabled
        ? `${ACTION_BUTTONS_LABELS.CONFIRM} (${countdown})`
        : ACTION_BUTTONS_LABELS.CONFIRM

    const buttons: BasicButtonProps[] = [
        {
            label: buttonLabel,
            className: `shadow-md btn btn-neutral dark:btn-success no-animation ${isButtonDisabled ? 'opacity-70 cursor-not-allowed' : ''}`,
            onClick: isButtonDisabled ? undefined : onClose,
            disabled: isButtonDisabled,
        },
    ]

    return (
        <div className="flex flex-col justify-center h-full gap-1 p-3 m-auto md:p-5">
            <FaceSmileIcon className="w-20 h-20 mx-auto mb-2" />
            <p className="text-lg font-semibold text-center">
                {requireManualCheck
                    ? BOOKING_PAGE_TEXT.RESERVATION_EMAIL_INSTRUCTION
                    : BOOKING_PAGE_TEXT.RESERVATION_CONFIRMATION_INSTRUCTION}
            </p>
            <span className="mx-auto text-base text-center text-warning">
                {requireManualCheck
                    ? BOOKING_PAGE_TEXT.RESERVATION_EMAIL_INSTRUCTION_NOTE
                    : BOOKING_PAGE_TEXT.RESERVATION_CONFIRMATION_INSTRUCTION_NOTE}
            </span>
            <ActionButtons containerClassName="mx-auto my-4" buttons={buttons} />
        </div>
    )
}

export default ReservationConfirmationPopupComponent
