import React, { useEffect, useState } from 'react'

import { createServerHelper } from '@/server/context'
import { TZDate } from '@date-fns/tz'
import { dehydrate } from '@tanstack/react-query'
import { addDays, addMonths, endOfDay, lastDayOfMonth, startOfMonth } from 'date-fns'

import { GetServerSidePropsContext } from 'next'

/* Hooks */
import { useGetTranslations } from '@/i18n/index.tsx'

/* Context */
import { useApplicationContext } from '@/context/ApplicationContext.tsx'

import { NOTIFICATION_TYPE } from '@/constants/constants'

/* Utils */
import { findNextAvailableBookingDay, formatDate } from '@/utils/calendarUtils.ts'
import { getDefaultVenueSettings } from '@/utils/stateUtils'
import { trpc } from '@/utils/trpc.ts'

import { useDefer } from '@/hooks/useDefer.ts'

import VenueInformationComponent from '@/components/AdminDashboard/VenueInformationComponent/VenueInformationComponent.tsx'
import BookingPageComponent from '@/components/BookingPageComponent/BookingPageComponent.tsx'

/* Components */
import CustomerLayout from '@/layouts/CustomerLayout.tsx'

import { ExtendedEventsT, ExtendedVenueSettingsT } from '@/types/globalTypes'

const BookingPage = ({
    venue,
    initialQueryKey,
    serverEvents,
    serverDate,
}: {
    venue: ExtendedVenueSettingsT
    serverEvents: ExtendedEventsT[]
    serverDate: Date
    initialQueryKey: {
        start_date: string
        end_date: string
        locale: string
        venue_id: string
    }
}) => {
    const { setToastNotification } = useApplicationContext()

    const { TOAST_NOTIFICATIONS_TEXT } = useGetTranslations()

    const {
        working_hours,
        reservation_lead_time,
        max_calendar_days,
        days_of_operation,
        name,
        logo_url,
        description,
        map_url,
        address,
        phone_number,
        email,
        website,
        instagram_link,
        linkedin_link,
        tiktok_link,
        youtube_link,
        facebook_link,
        allow_reservations,
        venue_timezone,
        require_manual_approval,
        allow_same_day_reservations,
    } = venue

    // Compute the server offset (the difference between the server date and the client's current time)
    const serverOffset = new Date(serverDate).getTime() - Date.now()
    // Create an "adjusted" current time using the offset so the client references the server time.
    const clientCurrentTime = new Date(Date.now() + serverOffset)

    const dateToday = new TZDate(serverDate, venue_timezone)

    const [selectedDate, setSelectedDate] = useState(() => {
        // If the current day is active and the current time is at least before the reservation_lead_time aka if the lead time is 1 hour before the starting time,
        return findNextAvailableBookingDay(
            dateToday,
            working_hours,
            reservation_lead_time,
            venue_timezone,
            clientCurrentTime,
        )
    })

    const [activeMonth, setActiveMonth] = useState({
        startOfMonth: formatDate(startOfMonth(selectedDate)),
        endOfMonth: formatDate(lastDayOfMonth(addMonths(selectedDate, 1))),
    })


    const [maxDate] = useState(addDays(dateToday, max_calendar_days))

    const { data, failureCount } = trpc.events.getEventsByDateRange.useQuery(
        {
            ...initialQueryKey,
            start_date: activeMonth.startOfMonth,
            end_date: activeMonth.endOfMonth,
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 30000,
            initialData: serverEvents,
            retry: () => {
                setToastNotification({
                    message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
                    type: NOTIFICATION_TYPE.ERROR,
                })
                return failureCount === 1
            },
        }
    )

   /*  useDefer(endOfDay(dateToday), () => {
        setDateToday(new TZDate(new Date(), venue_timezone))
    }) */

    // Remove other loading states and use initialLoadComplete for the layout
    return (
        <CustomerLayout initializePageLoad={false} isAuthorized={true}>
            <div className="animate-fade">
                <BookingPageComponent
                    dateToday={dateToday}
                    selectedDate={selectedDate}
                    minDate={dateToday}
                    maxDate={maxDate}
                    allow_reservations={allow_reservations}
                    days_of_operation={days_of_operation}
                    events={(data as ExtendedEventsT[]) || serverEvents}
                    setActiveMonth={setActiveMonth}
                    setSelectedDate={setSelectedDate}
                    venue_timezone={venue_timezone}
                    require_manual_approval={require_manual_approval}
                    allow_same_day_reservations={allow_same_day_reservations}
                    currentServerTime={clientCurrentTime} // Pass the adjusted current time
                />

                <VenueInformationComponent
                    address={address}
                    description={description}
                    email={email}
                    facebook_link={facebook_link}
                    instagram_link={instagram_link}
                    linkedin_link={linkedin_link}
                    logo_url={logo_url}
                    map_url={map_url}
                    name={name}
                    phone_number={phone_number}
                    tiktok_link={tiktok_link}
                    website={website}
                    working_hours={working_hours}
                    youtube_link={youtube_link}
                />
            </div>
        </CustomerLayout>
    )
}

export async function getServerSideProps(opts: GetServerSidePropsContext) {
    try {
        const { req, res, locale } = opts
        // First get venue settings so we know the venueTimezone
        const helpers = await createServerHelper({ req, res } as GetServerSidePropsContext)
        const venue_settings = await helpers.venueSettings.getVenueInformation.fetch()

        // Use venue's timezone to create the server date
        const serverDate = new TZDate(new Date(), venue_settings.venue_timezone)

        const initialStartOfMonth = formatDate(startOfMonth(serverDate))
        const initialEndOfMonth = formatDate(lastDayOfMonth(addMonths(serverDate, 1)))

        const queryKey = {
            start_date: initialStartOfMonth,
            end_date: initialEndOfMonth,
            locale,
            venue_id: venue_settings.id,
        }

        const serverEvents = await helpers.events.getEventsByDateRange.fetch(queryKey)
        const dehydratedState = dehydrate(helpers.queryClient)
        return {
            props: {
                venue: venue_settings,
                serverDate: JSON.parse(JSON.stringify(serverDate)),
                serverEvents,
                initialQueryKey: queryKey,
                trpcState: dehydratedState,
            },
        }
    } catch (error) {
        console.error('Error in getServerSideProps:', error)
        return {
            props: {
                venue: getDefaultVenueSettings(),
                serverDate: JSON.parse(JSON.stringify(new Date())),
                initialQueryKey: null,
                serverEvents: [],
                trpcState: {},
            },
        }
    }
}

export default BookingPage
